import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { AbpValidationError } from "@shared/components/validation/abp-validation.api";
import { mergeMap as _observableMergeMap, catchError as _observableCatch } from 'rxjs/operators';

import {
  AppliedJobServicesServiceProxy,
  CredentialDto,
  JobFiltersDetailsDto,
  SelectedFilterDto,
  SubscriptionDto,
  University,
  UserNotification,
} from "@shared/service-proxies/service-proxies";
import { environment } from "environments/environment.prod";
import { BsModalRef } from "ngx-bootstrap/modal";
import { BehaviorSubject, Observable, Subject, filter, pairwise, tap } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class CommonServiceService implements OnInit {
  selectedValue: any;
  registerSelectedValue: any;
  isFilterAdded: boolean;
  isAnswerAdded: boolean;
  captchaWaitingPopup: BsModalRef;
  indeedOTPModal :BsModalRef
  isValidation = new BehaviorSubject<boolean>(null);
  Answers: [];
  NoOfJob: any;
  skillIDs: [];
  subscriptionData: SubscriptionDto = new SubscriptionDto();
  credential: CredentialDto = new CredentialDto();
  totalAppliedJob: number;
  rejectedJobs: any;
  sourceId: any;
  pageTitle = new BehaviorSubject<any>(null);
  isPopupOpen = new BehaviorSubject<boolean>(false);
  isUploadCvPopupOpen: boolean = false;
  university: University = new University();
  JobFiltersDetailsDto: JobFiltersDetailsDto = new JobFiltersDetailsDto();
  questionobj = [
    {
      type: 'text',
      question: 'Your Profile',
      answer: null
    },
    {
      type: 'text',
      question: 'City City',
      answer: null
    },
    {
      type: 'text',
      question: 'Your Name',
      answer: null
    }
  ];
  static EventTypes = {
    "100": "Sign In Successful",
    "101": "Correct Username Password",
    "102": "Incorrect Username Password",
    "103": "Email Otp Verified",
    "104": "Incorrect Otp Entered",
    "105": "No Filter Applied",
    "106": "Captcha Solved",
    "107": "Captcha Try Again",
    "108": "Captcha Not Solved",
    "109": "Applying Job Started",
    "110": "Automation Success",
    "111": "Automation Fail",
    "112": "Email Verification Pending",
    "113": "Partially Completed Jobs",
    "114": "No Jobs Found",
    "115": "Authentication Failure",
    "116": "Image Captcha Pending",
    "117": "Validating Credential",
    "118": "Click On Applying Job",
    "119": "Cv Not Found",
    "120": "Authentication Failed",
    "121": "Too Many Requests",
    "122": "Set Your Password",
    "123": "Cv Found",
    "124": "An error occurred on the server side.",
    "125": "User Info Wrong",
    "131": "Two factor authentication pending",
    "132": "Two factor authentication success",
    "134": "Account has been temporarily blocked",
    "135": "LinkedIn captcha isn't displaying",
    "136": "Resume uploaded",
    "137": "Two factor authentication not completed",
    "138": "Something went wrong on LinkedIn's end",
    "139": "Something went wrong on Indeed's end",
    "140": "Click to fetch job list",
    "141": "Jobs have been found",
    "142": "Job Saving Completed",
    "143": "Job Saving Started",
    "144": "Click On Job Saving",
    "145": "Not Enough Jobs To Apply"
  };
  eventTypeOptions = [
    { value: "100", label: "Sign In Successful" },
    { value: "101", label: "Correct Username Password" },
    { value: "102", label: "Incorrect Username Password" },
    { value: "103", label: "Email Otp Verified" },
    { value: "104", label: "Incorrect Otp Entered" },
    { value: "105", label: "No Filter Applied" },
    { value: "106", label: "Captcha Solved" },
    { value: "107", label: "Captcha Try Again" },
    { value: "108", label: "Captcha Not Solved" },
    { value: "109", label: "Applying Job Started" },
    { value: "110", label: "Automation Success" },
    { value: "111", label: "Automation Fail" },
    { value: "112", label: "Email Verification Pending" },
    { value: "113", label: "Partially Completed Jobs" },
    { value: "114", label: "No Jobs Found" },
    { value: "115", label: "Authentication Failure" },
    { value: "116", label: "Image Captcha Pending" },
    { value: "117", label: "Validating Credential" },
    { value: "118", label: "Click On Applying Job" },
    { value: "119", label: "Cv Not Found" },
    { value: "120", label: "Authentication Failed" },
    { value: "121", label: "Too Many Requests" },
    { value: "122", label: "Set Your Password" },
    { value: "123", label: "Cv Found" },
    { value: "124", label: "An error occurred on the server side." },
    { value: "125", label: "User Info Wrong" },
    { value: "131", label: "Two factor authentication pending" },
    { value: "132", label: "Two factor authentication success" },
    { value: "134", label: "Account has been temporarily blocked" },
    { value: "135", label: "LinkedIn captcha isn't displaying" },
    { value: "136", label: "Resume uploaded" },
    { value: "137", label: "Two factor authentication not completed" },
    { value: "138", label: "Something went wrong on LinkedIn's end" },
    { value: "139", label: "Something went wrong on Indeed's end" },
    { value: "140", label: "Click to fetch job list" },
    { value: "141", label: "Jobs have been found" },
    { value: "142", label: "Job Saving Completed" },
    { value: "143", label: "Job Saving Started" },
    { value: "144", label: "Click On Job Saving" },
    { value: "145", label: "Not Enough Jobs To Apply"}
  ];

  static SourceType = {
    "101": "Web Site",
    "102": "LinkedIn",
    "103": "Indeed",
  };
  sourceTypeOptions = [
    { value: "101", label: "Web Site" },
    { value: "102", label: "LinkedIn" },
    { value: "103", label: "Indeed" },
  ];
  private previousUrl: string = "";
  jobFilters: any[];
  jobListings: any[];

  constructor(private router: Router,
    private http: HttpClient,
    private _appliedJobs: AppliedJobServicesServiceProxy
  ) { }
  ngOnInit(): void {

  }


  getEventString(eventNumber: number): string {
    return CommonServiceService.EventTypes[eventNumber?.toString()];
  }

  getSourceString(eventNumber: number): string {
    return CommonServiceService.SourceType[eventNumber.toString()];
  }

  toggleLoader(value: boolean) {
    this.isValidation.next(value);
  }

  getPreviousUrl(): string {
    return this.previousUrl;
  }
  setFilterValue(value: boolean) {
    this.isFilterAdded = value;
  }
  getFilterValue() {
    return this.isFilterAdded;
  }
  setJobNoValue(value: any) {
    this.NoOfJob = value;
  }
  getJobNoValue() {
    return this.NoOfJob;
  }
  setAnswerValue(value: boolean) {
    this.isAnswerAdded = value;
  }
  setSubscriptionValue(value) {
    this.subscriptionData = value;
  }
  getSubscriptionValue() {
    return this.subscriptionData;
  }
  getAnswerValue() {
    return this.isAnswerAdded;
  }
  setSelectedValue(value: any) {
    this.selectedValue = value;
  }

  setSourceId(value) {
    this.sourceId = value;
  }
  getSourceId() {
    return this.sourceId;
  }
  //credential
  setCredential(value) {
    this.credential = value;
  }
  getCredential() {
    return this.credential;
  }

  setTotalAppliedJob(values) {
    this.totalAppliedJob = values;
  }
  getTotalAppliedJob() {
    return this.totalAppliedJob;
  }
  setSkillIDs(value) {
    this.skillIDs = value;
  }
  getSkillIDs() {
    return this.skillIDs;
  }
  setAnswers(value) {
    this.Answers = value;
  }
  getAnswers() {
    return this.Answers;
  }
  setRejectedJob(value: any) {
    this.rejectedJobs = value;
  }
  getRejectedJob() {
    return this.rejectedJobs;
  }

  getSelectedValue() {
    return this.selectedValue;
  }
  selectedregisterSelectedValue(value: any) {
    this.registerSelectedValue = value;
  }
  getSelectedregisterSelectedValueValue() {
    return this.registerSelectedValue;
  }

  commonfilters = new BehaviorSubject<boolean>(null);

  commonChanges(value?) {
    console.log(' commonChanges value = ', value);
    this.commonfilters.next(value);
  }

  private commonChangesfilters: any = new Subject();
  commonChangesFilters = this.commonChangesfilters.asObservable();

  commonDetectChanges() {
    this.commonChangesfilters.next();
  }

  clickViews = new BehaviorSubject<boolean>(null); // Renamed BehaviorSubject

  handleClickChanges(value?) {
    // Renamed method
    this.clickViews.next(value);
  }
  validateEmail(email: string): boolean {
    const emailPattern = /^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
    return emailPattern.test(email);
  }

  validatePassword(password: string): boolean {
    const passwordPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d.`~!@#$%^&*()-_+={}|:;'"<>,.?/]{8,}$/;
    return passwordPattern.test(password);
  }

  validateOtp(otp: number): boolean {
    const otpPattern = /^[0-9]+$/;
    return otpPattern.test(String(otp));
  }
  validateEmojis(input: string): boolean {
    const trimmedInput = input.trim();
    if (trimmedInput === "") {
      return true; // Return false if the input is empty
    }
    const emojiPattern = /^[^\uD800-\uDFFF\uD83C\uD83D\uD83E\u2000-\u3300]+$/g;
    return emojiPattern.test(input);
  }

  validateName(input: string): boolean {
    const trimmedInput = input.trim();
    if (trimmedInput === "") {
      return true; // Return false if the input is empty
    }
    const namePattern = /^[a-zA-Z\s]+$/;
    return namePattern.test(input);
  }

  validateRating(input: string): boolean {
    const trimmedInput = input.trim();
    if (trimmedInput === "") {
      return true; // Return false if the input is empty
    }
    const rating = parseInt(input, 10);
    return !isNaN(rating) && rating >= 1 && rating <= 10;
  }

  logsEvent = new BehaviorSubject<any>({}); // Renamed BehaviorSubject
  setLogsEvent(value?) {
    this.logsEvent.next(value);
  }
  setUploadCvOpen(value?) {
    this.isUploadCvPopupOpen = value;
  }
  getUploadCvOpen() {
    return this.isUploadCvPopupOpen;
  }

  IsCredsValidate = new BehaviorSubject<boolean>(null);

  IsCredsValidateChanges(value?) {
    this.IsCredsValidate.next(value);
  }

  progressSubject = new BehaviorSubject<number>(null);
  setProgress(value: number) {
    this.progressSubject.next(value);
  }
  setUniversityValue(value) {
    this.university = value;
  }
  getUniversityValue() {
    return this.university;
  }
  canAllocateJobs(totalJobs: number, availableJobs: number): boolean {
    if (totalJobs > availableJobs) {
      abp.message.warn(
        `Total jobs needed (${totalJobs}) exceed the available jobs provided by the admin (${availableJobs}).`
      );
      return false;
    }
    return true;
  }

  containsOnlyPositiveNumbers(input: string) {
    const numbers = input?.trim();
    if (numbers === "") {
      return true;
    }
    const regex = /^[1-9]\d*$/;
    return regex.test(numbers);
  }
  //check difference between two dates
  calculateDifferenceInSeconds(firstdate: string, seconddate: string): number {
    if (firstdate && seconddate) {
      const date1Obj = new Date(firstdate);
      const date2Obj = new Date(seconddate.split('.')[0]);
      const differenceInMs = date2Obj.getTime() - date1Obj.getTime();
      const differenceInHours = differenceInMs / 1000;
      return differenceInHours;
    }
    return 0
  }
  convertSecondsToHoursMinutesAndSeconds(seconds) {
    if (!seconds || seconds < 0) return { hours: '00', minutes: '00', seconds: '00' };
    const hours = Math.floor(seconds / 3600).toString().padStart(2, '0');
    const remainingSecondsAfterHours = seconds % 3600;
    const minutes = Math.floor(remainingSecondsAfterHours / 60).toString().padStart(2, '0');
    const remainingSeconds = remainingSecondsAfterHours % 60;
    const secondsStr = Math.floor(remainingSeconds).toString().padStart(2, '0');
    return { hours, minutes, seconds: secondsStr };
  }
  getTimeinMinutesandSeconds(timeDifference) {
    const timeinminandsec = this.convertSecondsToHoursMinutesAndSeconds(timeDifference)
    const timeDisplayElement = document.getElementById('timerrr');
    if (timeDisplayElement)
      timeDisplayElement.innerText = `${timeinminandsec.hours}:${timeinminandsec.minutes}:${timeinminandsec.seconds}`;
  }
  closeTimePopup() {
    const timerElement = document.getElementById('timerrr');
    if (timerElement) {
      const confirmButton: any = document.querySelector('.swal2-confirm.swal2-styled');
      if (confirmButton) confirmButton.click();
    }
  }

  getIndeedJobs = (userId: string, sourceId: number) => {
    console.log("--- Indeed job process ----")
    this._appliedJobs.getIndeedJobs(userId, sourceId).subscribe()
  }

  getLinkedInJobs(selectedFilterData: any, startValue: any, userId: any, credentialId: any): void {
    const location = selectedFilterData.Location || '';
    const experienceLevels = (selectedFilterData['Experience level'] || []).join(',');
    const jobKeyword = (selectedFilterData['Job Title'] || []).join(',');
    const jobTypes = (selectedFilterData['Job type'] || []).join(',');
    const salary = (selectedFilterData['Salary'] || []).join(',');
    const remote = (selectedFilterData['Remote'] || []).join(',');
    const jobRange = (selectedFilterData['Job_Range'] || []).join(',');
    const start = startValue !== 0 ? startValue : 0;

    this.JobFiltersDetailsDto.location = location[0],
      this.JobFiltersDetailsDto.experienceLevels = experienceLevels,
      this.JobFiltersDetailsDto.jobKeywords = jobKeyword,
      this.JobFiltersDetailsDto.jobTypes = jobTypes,
      this.JobFiltersDetailsDto.salaries = salary,
      this.JobFiltersDetailsDto.remoteOptions = remote,
      this.JobFiltersDetailsDto.jobRanges = jobRange,
      this._appliedJobs.getLinkedInJobs(start, userId, credentialId, this.JobFiltersDetailsDto).subscribe();

    console.log(this.JobFiltersDetailsDto);
  }

  setJobsData(jobListings: []): void {
    this.jobListings = jobListings;

  }
  getJobsData(): { jobListings: any[] } {
    return {
      jobListings: this.jobListings,
    };
  }
  recieveNotifications = new BehaviorSubject<UserNotification>(null);

  getQuestionObj() {
    return this.questionobj;
  }
  sendNotifications(value?) {
    this.recieveNotifications.next(value);
  }
}

