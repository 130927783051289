import { ChangeDetectorRef, Injectable } from '@angular/core';
// import { BOT_REQUEST_URL } from '../constants/network';
import {io, Socket} from 'socket.io-client';

import { AppConsts } from '@shared/AppConsts';
import { AppSessionService } from '@shared/session/app-session.service';
import { CommonServiceService } from '@shared/common-services/common-service.service';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { combineLatest, interval, Subscription, switchMap, takeWhile } from "rxjs";
import { OptIndeedComponent } from '@shared/components/opt-indeed/opt-indeed.component';
import { ApplyJobComponent } from '@app/job-details/apply-job/apply-job.component';
import { throws } from 'assert';

const socket = io(AppConsts.BOT_REQUEST_URL_INDEED, { autoConnect: true, transports: ["websocket", "polling"] });
socket.on("connect", () => {
  // console.log(this.socket.id); // x8WIv7-mJelg7on_ALbx
  console.log("socket connected")
  // console.log('appSessionService.userId = ', appSessionService.userId);
});

socket.on("disconnect", () => {
  console.log("socket disconnected")
  // console.log(this.socket.id); // undefined
});
@Injectable({
  providedIn: 'root'
})
export class SocketService {

  subscriptions: Subscription[] = []

  private handleJobListSuccess(jobArrayJSON: any) {
    abp.message.success("Success! We’ve found a list of jobs for you");
    this._commonService.setJobsData(jobArrayJSON);
    this._route.navigate(['/app/details/manual-job-list']);
  }

  logoWithCustomHtml(sourceId) {
    const parentElement = document.querySelector('.swal2-popup')
    parentElement.insertAdjacentHTML('afterbegin', `<div class='logo-container-forcePlacement'>
      <img src=${sourceId === 1 ? "../../../assets/img/linkedin-logo.png" : "https://theme.zdassets.com/theme_assets/499832/4e561c74f705fcf9845569346594272e025055ba.svg"} width=${sourceId === 1 ? "40px" : "70px"}>
    </div>`);
  }

  private handleJobApplicationStart(message, dismissPopup = true){
    if(dismissPopup && this._commonService.captchaWaitingPopup){
      this._commonService.captchaWaitingPopup.hide();
    }
    const currentRoute = JSON.parse(localStorage.getItem("currentURL"));
    this._route.navigate([currentRoute]);
    abp.message.success(message);
    this.logoWithCustomHtml(2)
  }

  private handleSuccessApplyingJobs(message) {
    if (this._commonService.captchaWaitingPopup) {
      this._commonService.captchaWaitingPopup.hide();
    }
    abp.message.success(message);
    this._route.navigate(['/app/applied-jobs']);
  }

  private handleSuccessAuth(message) {
    abp.message.success(message, 'Indeed authentication')
    if (this._commonService.captchaWaitingPopup) {
      this._commonService.captchaWaitingPopup.hide();
    }

    setTimeout(
      () => {
        this.showAppliedJobsDialog()
      },
      4000
    )
  }

  private handleJobFilterApplicationSuccess(message) {
    if (this._commonService.captchaWaitingPopup) {
      this._commonService.captchaWaitingPopup.hide();
    }
    abp.message.success(message, 'Indeed filter')
    setTimeout(
      () => {
        this.showAppliedJobsDialog()
      },
      4000
    )
  }

  private handleBotInProgressActions(message){
    if (this._commonService.captchaWaitingPopup) {
      this._commonService.captchaWaitingPopup.hide();
    }
    abp.message.success(message);
    this._route.navigate(['/app/applied-jobs']);
  }

  private handleReloginRequest(){
    if (this._commonService.captchaWaitingPopup) {
      this._commonService.captchaWaitingPopup.hide();
    }
    this._commonService.toggleLoader(false);
    this._commonService.commonChanges()

    abp.message.error("Your Indeed login has expired, Please re-validate your account");
    // Since the source of indeed is 2
    // Error will navigate the screen to validate page
    this._route.navigate(['/app/details/source/2']);
  }

  private handleLoginRetryAttemptsExceeded(message){
    if (this._commonService.captchaWaitingPopup) {
      this._commonService.captchaWaitingPopup.hide();
    }
    this._commonService.toggleLoader(false);
    this._commonService.commonChanges()

    abp.message.error(message);
    // Since the source of indeed is 2
    // Error will navigate the screen to validate page
    this._route.navigate(['/app/details/source/2']);
  }

  private handleAuthenticationFailure(message){
    if (this._commonService.captchaWaitingPopup) {
      this._commonService.captchaWaitingPopup.hide();
    }
    this._commonService.toggleLoader(false);
    this._commonService.commonChanges()

    abp.message.error(message);
    // Since the source of indeed is 2
    // Error will navigate the screen to validate page
    this._route.navigate(['/app/details/source/2']);
  }

  private handleRequiredSignup(message: string){
    if (this._commonService.captchaWaitingPopup) {
      this._commonService.captchaWaitingPopup.hide();
    }
    this._commonService.toggleLoader(false);
    this._commonService.commonChanges()

    abp.message.error(message);
    // Since the source of indeed is 2
    // Error will navigate the screen to validate page
    this._route.navigate(['/app/details/source/2']);
  }

  private handleFailureApplyingJobs(message){
    if (this._commonService.captchaWaitingPopup) {
      this._commonService.captchaWaitingPopup.hide();
    }
    this._commonService.toggleLoader(false);

    abp.message.error(message);
  }


  private showAppliedJobsDialog(): void {
    this._commonService.captchaWaitingPopup = this._modalService.show(
      ApplyJobComponent,
      {
        class: 'MainCustomizModel',
        backdrop: 'static',
      }
    );
  }

  private handleBotAuthApproved = (message) => {
    abp.message.success(message);
    setTimeout(
      () => {
        this.showAppliedJobsDialog()
      },
      4000
    )
  }

  private handleBreakingChange(message){
    if (this._commonService.captchaWaitingPopup) {
      this._commonService.captchaWaitingPopup.hide();
    }
    this._commonService.toggleLoader(false);

    abp.message.error(message);
  }

  private unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }


  private async showOTP_Dialog(type, errorMessage?: string): Promise<boolean> {
      console.log("I am called 2.0")
      this._commonService.indeedOTPModal = await this._modalService.show(
        OptIndeedComponent,
        {
          class: "MainCustomizModel",
          backdrop: "static",
          initialState: {
            userId: abp.session.userId,
            sourceId: 2,
            type: type,
            errorMessage: errorMessage
            // sourceId: this.credentials.sourceId,
          }
        }
      );


      this.subscriptions.push(
        this._modalService.onHidden.subscribe(() => {
          console.log('on hidden fired = ', );
          this._commonService.indeedOTPModal.hide()
          this.unsubscribe()
        })
      );

      return new Promise<boolean>((resolve, reject) => {})
  }

  constructor(
    private appSessionService : AppSessionService,
    private _commonService: CommonServiceService,
    private _modalService: BsModalService,
    private _route: Router,
  ) {

    console.log("socket service started")
    socket.emit("ping", `{"cognito_id":"${appSessionService.userId}"}`);
  }

  removeListener = (listenerType) =>{
    socket.removeListener(listenerType)
  }

  public authCodeListener;
  public twoFactorOTPCodeListener;
  public invalidOTPCodeListener;
  public authSuccessMethpd;
  public authCodeFailureMethod;
  public phoneNumberVerificationMethod;
  public phoneNumberOTPVerificationMethod;
  public validationMethodFailureCaller;

  setValidateMethodListeners = (
    otpCodeListener,
    twoFactorOTPCodeListener,
    invalidOTPCodeListener,
    authSuccessMethpd,
    authCodeFailureMethod,
    phoneNumberVerificationMethod,
    phoneNumberOTPVerificationMethod
  ) => {

    this.authCodeListener = otpCodeListener
    console.log(this.authCodeListener)
    this.twoFactorOTPCodeListener = twoFactorOTPCodeListener
    console.log(this.twoFactorOTPCodeListener)
    this.invalidOTPCodeListener = invalidOTPCodeListener
    console.log(this.invalidOTPCodeListener)
    this.authSuccessMethpd = authSuccessMethpd
    console.log(this.authSuccessMethpd)
    this.authCodeFailureMethod = authCodeFailureMethod
    console.log(authCodeFailureMethod)
    this.phoneNumberVerificationMethod = phoneNumberVerificationMethod
    console.log(phoneNumberVerificationMethod)
    this.phoneNumberOTPVerificationMethod = phoneNumberOTPVerificationMethod
    console.log(phoneNumberOTPVerificationMethod)

    console.log("Current listeners: ", socket.listeners('new_msg'))
    socket.removeListener('new_msg')
    this.addListeners()
  }
  addListeners = () => {
    console.log("Listeners after clearing: ", socket.listeners('new_msg'))
    socket.on("new_msg", (data) => {
      console.log("New message on socket: ", data)
      switch(data.type){
        case "job_links": {
          if (this._commonService.captchaWaitingPopup) {
            this._commonService.captchaWaitingPopup.hide();
          }

          const jobLinksBody = JSON.parse(data.message)
          console.log("Job links body: ", jobLinksBody)
          console.log(jobLinksBody.links)
          this.handleJobListSuccess(jobLinksBody.links)
          break;
        }

        case "request_relogin": {
          this.handleReloginRequest()
          break;
        }

        case 'success': {
          console.log("Success call already present: ", this.authSuccessMethpd)
          if(this.authSuccessMethpd){
            this.authSuccessMethpd(data)
          }else{
            console.log("Callnig default method")
            this.handleSuccessAuth(data.message)
          }
          break;
        }

        case ("auth_code"):{
          if(this.authCodeListener){
            this.authCodeListener(data)
          }else{
            console.log("Iam called from here")
            this.showOTP_Dialog('code')
          }
          break;
        }

        case ("two_factor_auth_code"):
          console.log('%c auth_code from bot =  ', 'background: blue; color: yellow',);

          if(this.twoFactorOTPCodeListener){
            this.twoFactorOTPCodeListener(data)
          }
          else{
            console.log("Iam called from here")
            this.showOTP_Dialog('two_factor_code')
          }
          break;

        case 'failure':
        case 'inactive_account_alert':{
          if(this.authCodeFailureMethod){
            this.authCodeFailureMethod(data)
          }else{this.handleAuthenticationFailure(data.message)}
          break;
        }

        case 'account_signup_required': {
          this.handleRequiredSignup(data.message)
          break;
        }

        case 'retry_attempts_exceeded':{
          this.handleLoginRetryAttemptsExceeded(data.message)
          break
        }

        case 'resume_not_found': {
          this.handleBreakingChange(data.message)
          break
        }

        case 'failure_applying_jobs':
        case 'no_jobs_found': {
          this.handleFailureApplyingJobs(data.message)
          break
        }

        case 'success_applying_jobs':{
          this.handleSuccessApplyingJobs(data.message)
          break
        }

        case 'two_factor_auth_code':{
          if(this.twoFactorOTPCodeListener){
            this.twoFactorOTPCodeListener(data)
          }
          else{
            console.log("Iam called from here")
            this.showOTP_Dialog('two_factor_code')
          }
          break
        }

        case 'filter_application_complete': {
          this.handleJobFilterApplicationSuccess(data.message)
          break
        }

        case 'bot_auth_approved':{
          this.handleBotAuthApproved(data.message)
          break;
        }

        case 'job_filters_applied': {
          this.handleJobFilterApplicationSuccess(data.message)
          break;
        }

        case('phone_number_verification_required'): {
          if(this.phoneNumberVerificationMethod){
            this.phoneNumberVerificationMethod()
          }
          break;
        }
        case 'invalid_phone_number_for_verification': {
          if(this.phoneNumberVerificationMethod){
            /**
             * Submit error
             */
            this.phoneNumberVerificationMethod(data)
          }
          break;
        }

        case ('phone_number_verification_otp_required'): {
          if(this.phoneNumberOTPVerificationMethod){
            this.phoneNumberOTPVerificationMethod()
          }
          break;
        }

        case ('invalid_otp_for_phone_number_verification'): {
          if(this.phoneNumberOTPVerificationMethod){
            this.phoneNumberOTPVerificationMethod(data)
          }
          break;
        }

        case ('invalid_otp_code'): {
            if(this.invalidOTPCodeListener){
              this.invalidOTPCodeListener(data)
            }else{
              this.showOTP_Dialog('code', data.message)
            }
            break;
        }
      }
    });
    console.log("Listeners added")
  }
}
