import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, Injector, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ValidateProcessComponent } from "@app/validate-process/validate-process.component";
import { AppComponentBase } from "@shared/app-component-base";
import { AppAuthService } from "@shared/auth/app-auth.service";
import { CommonServiceService } from "@shared/common-services/common-service.service";
import {
  AccountServiceProxy,
  UserServiceProxy,
} from "@shared/service-proxies/service-proxies";
import { BsModalService } from "ngx-bootstrap/modal";
@Component({
  selector: 'app-opt-indeed',
  templateUrl: './opt-indeed.component.html',
  styleUrls: ['./opt-indeed.component.css']
})
export class OptIndeedComponent extends AppComponentBase implements OnInit {
  id: any;
  otp: any;
  result: any;
  saving = false;
  isEmailConfirmed: boolean;
  isResendOtp: boolean = false;
  isConfirmingOTP: boolean;
  @Input() userId: number;
  @Input() sourceId: number;
  @Input() type: string;
  isValidOtp: boolean = true;
  @Input() errorMessage: string;
  constructor(
    injector: Injector,
    private _activatedRouter: ActivatedRoute,
    private _userService: UserServiceProxy,
    private _accountService: AccountServiceProxy,
    private _modalService: BsModalService,
    private authService: AppAuthService,
    private _router: Router,
    private _commonService: CommonServiceService,
    private http: HttpClient
  ) {
    super(injector);
  }
  ngOnInit(): void {
    console.log('this.userId = ', this.userId);
    console.log('this.sourceId = ', this.sourceId);
    console.log("Type: ", this.type)
  }

  onEnterKeyPressed(event: Event): void {
    event.preventDefault();
  }

  confirmOtp() {
    this.isConfirmingOTP = true;
    if (this.otp == null || this.otp == "") {
      this.notify.info("Please provide one-time password...!!!");
    } else {

      let url = `https://v2.bot.indeed.applygenie.ai/bot/${this.userId}/${this.sourceId}/${this.type}/reply`
      let body = {code : this.otp}
      let options_: any = {

        observe: "response",
        responseType: "blob",
        headers: new HttpHeaders({
          "Accept": "text/plain",
          'Content-Type': 'application/json'
        })
      };

      this.http.post(url, body, options_).subscribe((res) => {
        console.log('res = ', res);
      })
      this._commonService.indeedOTPModal.hide();
    }
  }

  resendOTP() {
    this.isResendOtp = true;
    this._accountService.resendOTPIndeed(this.userId).subscribe((res) => {
      this.isResendOtp = false;
    })
  }

  back() {
    this._commonService.indeedOTPModal.hide();
    this.saving = false;
    this.isConfirmingOTP = false;
    // this._router.navigate(["/account/register"]);
  }

  trim(value: string): void {
    if (value != null && typeof value === 'string') {
      this.otp = value.trim();
    }
  }

  validateOtp() {
    const otp = this.otp?.trim(); // Trim input and handle possible null
    this.isValidOtp = otp
      ? this._commonService.validateOtp(otp)
      : true;
  }

  isValidInput(): boolean {
    return this.otp && this.otp.length === 6 && this.isValidOtp;
  }

}

