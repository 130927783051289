export class AppConsts {

    static remoteServiceBaseUrl: string ;
    static appBaseUrl: string;
    static appBaseHref: string; // returns angular's base-href parameter value if used during the publish

    static BOT_REQUEST_URL_INDEED:string   = "https://v2.bot.indeed.applygenie.ai/";
    static BOT_REQUEST_URL_LINKEDIN:string = "https://v2.api.applygenie.ai";

    static localeMappings: any = [];

    static readonly userManagement = {
        defaultAdminUserName: 'admin'
    };

    static readonly localization = {
        defaultLocalizationSourceName: 'LinkedInRender'
    };

    static readonly authorization = {
        encryptedAuthTokenName: 'enc_auth_token'
    };
}
