import { Component, Injector, OnInit } from '@angular/core';
import { AppComponentBase } from '@shared/app-component-base';
import { CommonServiceService } from '@shared/common-services/common-service.service';
import { SessionServiceProxy, SubscriptionDto, SubscriptionServiceServiceProxy } from '@shared/service-proxies/service-proxies';
import { Router } from '@angular/router';

@Component({
  selector: 'app-apply-job',
  templateUrl: './apply-job.component.html',
  styleUrls: ['./apply-job.component.css']
})
export class ApplyJobComponent extends AppComponentBase implements OnInit {
  isAnsSubmitted: boolean;
  isfilterSubmitted: boolean;
  jobApplied: any
  totalJobs: any;
  reamainingJobs: any;
  userId: number;
  subscription: SubscriptionDto = new SubscriptionDto();
  isManualSaving: boolean
  constructor(injector: Injector,
    private _commonService: CommonServiceService,
    private _sessionService: SessionServiceProxy,
    private _subService: SubscriptionServiceServiceProxy,
    private route: Router) {
    super(injector);
  }

  ngOnInit(): void {
    this.isAnsSubmitted = this._commonService.getAnswerValue();
    this.isfilterSubmitted = this._commonService.getFilterValue();
    this._sessionService.getCurrentLoginInformations().subscribe(res => {
      this.userId = res.user.id;
      this.getSubscriptionDetail();
    })
  }

  getSubscriptionDetail() {
    this._subService.getSubscriptionByUserID(this.userId).subscribe(res => {
      this.subscription = res;
    })
  }

  apply() {
    this.subscription = this._commonService.getSubscriptionValue();
    this.reamainingJobs = this.subscription.remainingJobs - this._commonService.getJobNoValue();
    this._subService.updateJobs(this.userId, this.reamainingJobs).subscribe(res => {
    })
  }
}
