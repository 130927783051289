<html lang="en">
  <div style="width: 100%;" class="login-box otp-box-v2 px-2">
    <div class="row align-items-stretch h-100">
      <div class="col p-0">
        <div class="card h-100 rounded-0">
          <div class="card-body login-card-body py-4">
            <div class="d-flex align-items-center h-100">
              <div class="login-form m-auto py-5 w-100">
                <h4 class="text-center mb-3">
                  {{ "One Time Password received on your Email or Phone" | localize }}
                </h4>
                  <div class="form-group mb-1">
                    <div class="input-group">
                      <i class="fal fa-lock position-absolute input-icon"></i>
                      <input
                        style="height: 48px;"
                        type="text"
                        class="form-control pl-5"
                        name="otp"
                        [(ngModel)]="otp"
                        (input)="validateOtp()"
                        maxlength="6"
                        appNoSpaceInput
                        (blur)="trim($event.target.value)"
                        [placeholder]="'Enter here' | localize"
                        required
                        #OtpModel="ngModel"
                        #OtpEl
                      />
                    </div>
                    <div *ngIf="!isValidOtp || errorMessage" class="text-danger matchPasswords">
                      Please Enter a valid one-time password
                    </div>
                    <abp-validation-summary
                      [control]="OtpModel"
                      [controlEl]="OtpEl"
                    ></abp-validation-summary>
                  </div>

                  <div class="form-group row">

                    <!-- <div class="col-md-12">
                      <div class="form-group text-right">
                        <button
                          type="button"
                          class="send_btn"
                          (click)="resendOtp()"
                        >
                          Re-send OTP
                        </button>
                      </div>
                    </div> -->

                    <div class="col-md-2">
                      <button
                        type="submit"
                        (click)="back()"
                        class="btn border btn-block w-100 login-btn"
                      >
                        Back
                      </button>
                    </div>


                    <div class="col-md-2" style="margin-left: 3rem; margin-right: 2rem;">
                      <button
                        type="submit"
                        *ngIf="type == 'phone_number_otp'"
                        (click)="resendOTP()"
                        class="btn border btn-block w-100 login-btn"
                      >
                        {{isResendOtp ? "Resending..." : "Resend"}}
                        <img *ngIf="isResendOtp" class="spinnerLoader ms-2" src="../assets/img/spinner.gif"
                        alt="" />
                      </button>
                    </div>

                    <div class="col-md-6 mt-md-0 mt-2">
                      <button
                        (click)="confirmOtp()"
                        type="submit"
                        class="btn btn-primary btn-block w-100 login-btn"
                        [disabled]="
                          isConfirmingOTP || !isValidOtp || !isValidInput()
                        "
                      >
                        {{ "Confirm" | localize
                        }}<img
                          *ngIf="isConfirmingOTP"
                          class="spinnerLoader ms-2"
                          src="../assets/img/spinner.gif"
                          alt=""
                        />
                      </button>
                    </div>

                  </div>
                  <p class="otp-note">
                    If you are unable to receive an OTP after multiple attempts,
                    try using a different browser or clear the cookies and cache
                    in your current browser resolve the issue.
                  </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</html>

<!-- src\assets\img -->
<!-- E:\3ARVIND\Ankush\Projects\ApplyGenie\apply-genie-ui-angular\src\assets\img\Geniee.png -->
