<html lang="en">
<div class="alertModel customizModel" id="exampleModalCenter" *ngIf="!isManualSaving">
  <div class="modal-dialog-centered ms-auto new-center-modal-dialog">
    <div class="modal-content">
      <div class="tests py-2 px-5 text-center">
        <img src="../../assets/img/inprogress.png" class="mb-4" width="80" alt="inprogress">
        <h6 class="mb-0">
          Automation is in progress. Please wait... It'll take you to verification page if required
        </h6>
        <div class="w-100 text-center">
          <img src="../../assets/img/popup.gif" width="90" alt="">
        </div>
        <h6 class="grayCol mb-4">
          Please do not exit page.<br> Process can take up to a few minutes.
        </h6>
      </div>
    </div>
  </div>
</div>
<div class="alertModel customizModel" id="exampleModalCenter" *ngIf="isManualSaving">
  <div class="modal-dialog-centered ms-auto new-center-modal-dialog">
    <div class="modal-content">
      <div class="tests py-2 px-5 text-center">
        <img src="../../assets/img/inprogress.png" class="mb-4" width="80" alt="inprogress">
        <h6 class="mb-0">
          Retrieving job listings. You may be taken to the verification page if required
        </h6>
        <div class="w-100 text-center">
          <img src="../../assets/img/popup.gif" width="90" alt="">
        </div>
        <h6 class="grayCol mb-4">
          Please do not exit page.<br> Process can take up to a few minutes.
        </h6>
      </div>
    </div>
  </div>
</div>

</html>